/**
 * Never edit this file manually. It is generated as part of the building process
 */
export const AppInfo = {
    name: 'medion-alditalk-client',
    appName: 'medion-alditalk-client',
    version: '2.0.3' ,
    defaultLocale: 'de',
    di18nMode: 'TRANSLATE',
    git: {
        shortHash: 'dde1c9d',
        hash: 'dde1c9d79043f9f53988962aec7cbb5bb97c911d',
        subject: 'Merge branch \'R-6629\' into \'next\'',
        sanitizedSubject: 'Merge-branch-R-6629-into-next',
        authoredOn: '1727041315',
        committedOn: '1727041315',
        branch: 'HEAD',
    }
};

