// tslint:disable: prefer-const
// tslint:disable: variable-name

import { isNullOrUndefined } from '@mvneco/eb-core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActivationActions, ActivationActionTypes } from '../actions/activation.actions';
import { CustomerDataModel } from '../models/customer-data.model';
import { IdDocumentDataModel, IdentificationCustTypes } from '../models/id-document.model';
import { TariffDataModel, TariffOptionGroupModel, TariffOptionModel } from '../models/tariff-data.model';
import { BankAccountDataModel } from '../models/bank-account-data.model';
import { SimDataModel } from '../models/sim-data.model';
import { ActivationErrorsModel } from '../models/activation-errors.model';
import { ActivationConfigModel } from '../models/activation-config.model';
import { initialProperties, PropertyConsentModel } from '../models/property-consent.model';

export interface ActivationState {
    errors: ActivationErrorsModel;
    config: ActivationConfigModel;
    activationStatus: string;
    simData: SimDataModel | null;
    customerData: CustomerDataModel | null;
    tariffData: TariffDataModel | null;
    idDocumentData: IdDocumentDataModel | null;
    bankAccountData: BankAccountDataModel | null;
    availableIdentifications: IdentificationCustTypes | null;
    properties: PropertyConsentModel;
    ackMarketing: boolean;
    simSet: boolean;
    acceptTerms: boolean;
    // TODO adapt to RAITT environment in which dispatching action SetAddressValidation may not be meaningful
    addressValid?: boolean;
    noTpiCheck?: boolean;
    rtccPaymentStatus?: string;
    prohibitOptionsWithMandatoryPaymentMethodBank?: boolean; // introduced in #6352 per change request MDAT-1181
}

export const initialActivationState: ActivationState = {
    errors: {
        simDataError: null
    },
    config: {
    },
    activationStatus: null,
    simData: null,
    customerData: null,
    tariffData: null,
    idDocumentData: {
        documentTypes: null,
        documentCategories: null,
        selectedDocumentType: null,
        selectedDocumentCategory: null,
        countries: null,
        country: null,
        validUntilDate: null
    },
    bankAccountData: {
        paymentMethod: 'Guthaben',
        selected: null,
        accountOwner: null,
        iban: null
    },
    availableIdentifications: null,
    properties: { ...initialProperties },
    ackMarketing: null,
    simSet: false,
    acceptTerms: false,
    // TODO adapt to RAITT environment in which dispatching action SetAddressValidation may not be meaningful
    addressValid: null,
    noTpiCheck: false,
    rtccPaymentStatus: null,
    prohibitOptionsWithMandatoryPaymentMethodBank: false
};


export const INITIAL_STORE_STATE = initialActivationState;

export function reducer(state = initialActivationState, action: ActivationActions): ActivationState {
    switch (action.type) {
        case ActivationActionTypes.Activation_Reset: {
            return INITIAL_STORE_STATE;
        }
        case ActivationActionTypes.Activation_State_Load: {
            return {
                ...action.payload,
                simSet: true
            };
        }
        case ActivationActionTypes.Activation_Finish: {
            return INITIAL_STORE_STATE;
        }

        case ActivationActionTypes.Activation_Start_Failure: {
            return { ...state, errors: { ...state.errors, simDataError: action.payload } };
        }
        case ActivationActionTypes.SimData_Update: {
            return { ...state, simData: action.payload };
        }
        case ActivationActionTypes.CustomerData_Update: {
            return {
                ...state,
                customerData: action.payload,
            };
        }

        case ActivationActionTypes.NoTpiCheck_Update: {
            return {
                ...state,
                noTpiCheck: action.payload
            };
        }

        case ActivationActionTypes.TariffData_Load: {
            return { ...state, tariffData: action.payload };
        }
        case ActivationActionTypes.TariffData_UpdateOptionStatus: {
            return state;
        }
        case ActivationActionTypes.IdDocumentData_SetDocumentTypes: {
            const tmp = action.payload;
            const tmpDocumentData: IdDocumentDataModel = {
                documentTypes: !isNullOrUndefined(tmp.documentTypes) ? tmp.documentTypes :
                    state.idDocumentData.documentTypes,
                documentCategories: !isNullOrUndefined(tmp.documentCategories) ? tmp.documentCategories :
                    state.idDocumentData.documentCategories,
                selectedDocumentType: tmp.selectedDocumentType,
                selectedDocumentCategory: tmp.selectedDocumentCategory,
                countries: !isNullOrUndefined(tmp.countries) ? tmp.countries : state.idDocumentData.countries,
                country: tmp.country,
                validUntilDate: tmp.validUntilDate
            };

            // console.log(tmpDocumentData);
            return {
                ...state,
                idDocumentData: tmpDocumentData
            };
        }
        case ActivationActionTypes.BankAccount_Update: {
            return {
                ...state,
                bankAccountData: action.payload
            };
        }
        case ActivationActionTypes.IdentificationTypes_Set: {
            return { ...state, availableIdentifications: action.payload };
        }

        case ActivationActionTypes.PropertyConsents_Set: {
            // console.log(action.payload);
            const key = Object.keys(action.payload)[0];
            // console.log(key);

            const tmpProperties = {
                ...state.properties,
                [key]: action.payload[key]
            };

            // console.log(state.properties);
            // console.log(tmpProperties);
            return {
                ...state,
                properties: tmpProperties
            };
        }

        case ActivationActionTypes.AllPropertyConsents_Set: {
            let tmpProperties = state.properties;

            let property: { [name: string]: PropertyConsentModel }[] = [];

            let key1 = 'bestandsdaten_alle_produkte';
            property = {
                ...property,
                [key1]: [
                    { name: 'bestandsdaten_alle_produkte_neu_email', value: 'true' },
                    { name: 'bestandsdaten_alle_produkte_neu_phone', value: 'true' },
                    { name: 'bestandsdaten_alle_produkte_neu_sen', value: 'true' },
                ]
            };
            let key2 = 'marktforschung';
            property = {
                ...property,
                [key2]: [
                    { name: 'marktforschung_email', value: 'true' },
                    { name: 'marktforschung_phone', value: 'true' },
                    { name: 'marktforschung_sen', value: 'true' },
                ]
            };

            let key3 = 'nutzungsprofil_bedarfsgerechte_produkte_anbieten';
            property = {
                ...property,
                [key3]:
                    [{ name: 'nutzungsprofil_bedarfsgerechte_produkte_anbieten_traffic_data', value: 'true' },
                    { name: 'nutzungsprofil_bedarfsgerechte_produkte_anbieten_user_data', value: 'true' }]
            };
            let key4 = 'nutzungsprofil_bedarfsgerechte_produkte_gestalten';
            property = {
                ...property,
                [key4]:
                    [{ name: 'nutzungsprofil_bedarfsgerechte_produkte_gestalten_traffic_data', value: 'true' },
                    { name: 'nutzungsprofil_bedarfsgerechte_produkte_gestalten_user_data', value: 'true' }]
            };


            const key = Object.keys(property).forEach(it => {
                tmpProperties = {
                    ...tmpProperties,
                    [it]: property[it]
                };
            });

            return {
                ...state,
                properties: tmpProperties
            };
        }

        case ActivationActionTypes.PropertyConsents_Reset: {

            return {
                ...state,
                properties: { ...initialProperties, document: state.properties.document }
            };
        }

        case ActivationActionTypes.AckMarketing_Set: {

            return {
                ...state,
                ackMarketing: action.payload
            };
        }

        case ActivationActionTypes.MnpSelectedProviderId_Set: {
            const customerData = {
                ...state.customerData,
                mnpData: { ...state.customerData.mnpData, selectedProviderId: action.payload }
            };
            // console.log('customerData', customerData);
            return {
                ...state,
                customerData
            };
        }

        case ActivationActionTypes.AcceptTerms__Update: {

            return {
                ...state,
                acceptTerms: action.payload
            };
        }

        // TODO adapt to RAITT environment in which dispatching action SetAddressValidation may not be meaningful at all (i.e. remove it)
        case ActivationActionTypes.AddressValid_Set: {

            // console.log('address valid', action.payload);
            return {
                ...state,
                addressValid: action.payload
            };
        }

        case ActivationActionTypes.RtccPaymentStatus_Set: {
            return {
                ...state,
                rtccPaymentStatus: action.payload
            };
        }

        case ActivationActionTypes.ProhibitOptionsWithMandatoryPaymentMethodBank_Set: {
            return {
                ...state,
                prohibitOptionsWithMandatoryPaymentMethodBank: action.payload
            };
        }

        default:
            return state;
    }
}

function updateOrOptionGroup(optionGroup: TariffOptionGroupModel, option: TariffOptionModel, isSelected: boolean) {
    option.isSelected = isSelected;
    // Because of the OR option group, we just update the current option.
    optionGroup.tariffOptions[option.code] = option;

    // Calculate if the option group has some option selected.
    // OR option group is selected if at least one option is selected.
    if (isSelected) {
        optionGroup.hasOptionSelected = true;
    } else {
        optionGroup.hasOptionSelected = false;
        for (const optionCode of Object.keys(optionGroup.tariffOptions)) {
            if (optionGroup.tariffOptions[optionCode].isSelected) {
                optionGroup.hasOptionSelected = true;
                break;
            }
        }
    }
}

function updateXorOptionGroup(optionGroup: TariffOptionGroupModel, option: TariffOptionModel, isSelected: boolean) {
    option.isSelected = isSelected;
    optionGroup.tariffOptions[option.code] = option;

    if (isSelected) {
        // Because of the XOR option group, all other options must be unselected.
        for (const optionCode of Object.keys(optionGroup.tariffOptions)) {
            if (optionCode !== option.code) {
                optionGroup.tariffOptions[optionCode].isSelected = false;
            }
        }
    }

    // Calculate if the option group has some option selected.
    // Because only one option can be selected, to check if an XOR option group is selected
    // it's enough to check only if the current option is selected.
    optionGroup.hasOptionSelected = isSelected;
}

export const selectActivationState = createFeatureSelector<ActivationState>('activation');

export const select_config = (state: ActivationState) => state.config;
export const select_simDataError = (state: ActivationState) => state.errors.simDataError;
export const select_simData = (state: ActivationState) => state.simData;
export const select_customerData = (state: ActivationState) => state.customerData;
export const select_tariffData = (state: ActivationState) => state.tariffData;
export const select_tariffData_hasOptionSelected = (state: ActivationState) => !isNullOrUndefined(state.tariffData) ?
    state.tariffData.hasOptionSelected : false;
export const select_idDocumentData = (state: ActivationState) => state.idDocumentData;
export const select_bankAccountData = (state: ActivationState) => state.bankAccountData;
export const select_availableIdentData = (state: ActivationState) => state.availableIdentifications;
export const select_addressValid = (state: ActivationState) => state.addressValid;
export const select_noTpiCheck = (state: ActivationState) => state.noTpiCheck;
export const select_rtccPaymentStatus = (state: ActivationState) => state.rtccPaymentStatus;
export const select_prohibitOptionsWithMandatoryPaymentMethodBank = (state: ActivationState) => state.prohibitOptionsWithMandatoryPaymentMethodBank;

export const selectConfig = createSelector(
    selectActivationState,
    select_config
);

export const selectSimDataError = createSelector(
    selectActivationState,
    select_simDataError
);

export const selectSimData = createSelector(
    selectActivationState,
    select_simData
);

export const selectCustomerData = createSelector(
    selectActivationState,
    select_customerData
);

export const selectTariffData = createSelector(
    selectActivationState,
    select_tariffData
);

export const selectTariffData_hasOptionSelected = createSelector(
    selectActivationState,
    select_tariffData_hasOptionSelected
);

export const selectIdDocumentData = createSelector(
    selectActivationState,
    select_idDocumentData
);

export const selectTariffOption_isSelected = createSelector(
    selectActivationState,
    (activationData: ActivationState, props) => activationData.tariffData == null ? null :
        activationData.tariffData.tariffOptionGroups[props.groupCode].tariffOptions[props.optionCode].isSelected
);

export const selectBankAccountData = createSelector(
    selectActivationState,
    select_bankAccountData
);

export const selectAvailableIdentData = createSelector(
    selectActivationState,
    select_availableIdentData
);

export const selectAddressValid = createSelector(
    selectActivationState,
    select_addressValid
);

export const selectNoTpiCheck = createSelector(
    selectActivationState,
    select_noTpiCheck
);

export const selectRtccPaymentStatus = createSelector(
    selectActivationState,
    select_rtccPaymentStatus
);

export const selectProhibitOptionsWithMandatoryPaymentMethodBank = createSelector(
    selectActivationState,
    select_prohibitOptionsWithMandatoryPaymentMethodBank
);
