import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {
  CustomerRegistration2RequestDto,
  CustomerRegistration2RequestOptionsOptionsAnonymDto,
  CustomerRegistration2ResponseDto,
  OptionTypeDto,
  BankAccountDataTypeDto,
  IResetProcessResponseDto,
  IResetProcessRequestDto,
  IPropertyTypeDto,
  CustomerRegistration2RequestCustomerCustomerMergedDto,
  ResourceGetSim2RequestDto,
  ResourceGetSim2ResponseDto,
  IProcessPrecontractualInformationRequestDto,
  IProcessPrecontractualInformationResponseDto,
  IFileAttachmentTypeDto,
  IIdentificationConfirmRequestDto,
  IIdentificationConfirmResponseDto,
  IGetPrecontractualDocumentRequestDto,
  IGetPrecontractualDocumentResponseDto,
  IsOrderCancellationAllowedResponseDto,
  IsOrderCancellationAllowedRequestDto,
  ContinueRegistrationRequestDto,
  ContinueRegistrationResponseDto,
  CustomerConsentSettingTypeDto,
  CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto,
  CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto
} from 'src/app/shared/api-types';
import * as fromActivationReducer from '../store/reducers/activation.reducer';
import { ActivationState } from '../store/reducers/activation.reducer';
import { MnpService } from './mnp.service';
import { isEmptyInputValue, isNullOrUndefined } from '@mvneco/eb-core';
import { AuthService } from '../../auth/services/auth.service';
import { ProductCatalogueService } from './product-catalogue.service';
import { TariffDataModel } from '../store/models/tariff-data.model';
import { MnpData } from '../models/mnp-data.model';
import { UserModel } from '../../auth/store/models/user.model';
import * as fromAppSettingsReducer from '../../app-settings/store/reducers/app-settings-config.reducer';
import * as fromAppSettingsSelectors from '../../app-settings/store/selectors/app-settings.selectors';
import { AppSettingsConfigState } from '../../app-settings/store/reducers/app-settings-config.reducer';
import { initialProperties, PropertyConsentModel } from '../store/models/property-consent.model';
import { ContactAddress } from '../models/contact-address.model';
import moment from 'moment';
import { TimeHandlerService } from 'src/app/services/time-handler.service';

// TODO Remove as possibly unused in RAITT BE environment?
export interface IFileDataCustDto {
  dateAndTime: string;
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ActivationService {
  public invalidAddress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public originalAddress$: BehaviorSubject<ContactAddress> = new BehaviorSubject<ContactAddress>(null);
  
  /**
   * Whether submission of contract documents via email to the current customer took place within current registration journey?
   * Used in both RAITT and non-RAITT BE environments.
   */
  public earlyeecc$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  
  /**
   * Whether customer initiated contract documents downloading within the current registration journey?
   * Used in both RAITT and non-RAITT BE environments.
   */
  public downloadedDocument$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  /**
   * Whether customer performed successful contract documents download within the current registration journey?
   * Used in both RAITT and non-RAITT BE environments.
   */
  public fileData$: BehaviorSubject<IFileDataCustDto> = new BehaviorSubject<IFileDataCustDto>(null);

  public changedSimInput$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Whether clicking the order cancellation button should be available.
   * Used in RAITT BE environment only.
   */
  public cancellationAllowed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private mnpService: MnpService,
    private authService: AuthService,
    private productCatalogueService: ProductCatalogueService,
    private storeActivation: Store<ActivationState>,
    private appSettingsStore: Store<fromAppSettingsReducer.AppSettingsConfigState>,
    private timeHandlerService: TimeHandlerService
  ) { }

  public resourceGetSim2(request: ResourceGetSim2RequestDto): Observable<ResourceGetSim2ResponseDto> {
    return this.httpClient.post
      <ResourceGetSim2ResponseDto>('services/V10/aldi-talk-internet-service-service/resourceGetSim2', request)
      .pipe(
        catchError(err => of(err)));
  }


  public getActivationState(): Observable<ActivationState> {
    return this.authService.getLoggedUser().pipe(
      switchMap((userModel: UserModel) => {
        if (!isNullOrUndefined(userModel) && !isNullOrUndefined(userModel.resource)) {
          // console.log(userModel);
          return this.productCatalogueService.getProductCatalogue(userModel.resource.sim).pipe(map((tariffData: TariffDataModel) =>
            this.mapToActivationData(tariffData, userModel)));
        }
        else if (userModel.subStatus === 'COMPLETE' || userModel.subStatus === 'READY_FOR_ACTIVATION') {
          // console.log('here');
          return of(null).pipe(map((tariffData: TariffDataModel) => this.mapToActivationData(tariffData, userModel)));
        }
        return throwError('Err');
      })
    );
  }

  // Third parameter is optional, only in case status is READY_FOR_ACTIVATION (simNumber)
  public mapToActivationData(tariffData: TariffDataModel, userModel: UserModel, simNumber?: string): ActivationState {
    const activationState: ActivationState = JSON.parse(JSON.stringify(fromActivationReducer.INITIAL_STORE_STATE));

    // console.log('activationState: start', activationState);
    // console.log('userModel: ', userModel);
    if (!isNullOrUndefined(tariffData)) {
      activationState.tariffData = tariffData;
    }

    if (!isNullOrUndefined(userModel)) {
      // console.log('!isNullOrUndefined(userModel)', !isNullOrUndefined(userModel));
      activationState.activationStatus = userModel.subStatus;

      if (!isNullOrUndefined(userModel.resource)) {
        // console.log('userModel.resource', userModel.resource);
        activationState.simData = {
          sim: userModel.resource.sim,
          puk2: null,
          oldmsisdn: !isNullOrUndefined(userModel.resource.msisdn) ? userModel.resource.msisdn : null
        };
      }

      // EB-17713, in case resource is null then we use simNumber (only for READY_FOR_ACTIVATION status)
      if (isNullOrUndefined(userModel.resource) && !isNullOrUndefined(simNumber)) {
        activationState.simData = {
          sim: simNumber,
          puk2: null,
          oldmsisdn: null
        };
      }

      if (!isNullOrUndefined(userModel.customer)) {
        // console.log('uuserModel.customer', userModel.customer);
        let mnpData: MnpData = null;
        if (!isNullOrUndefined(userModel.customer.options) && !isNullOrUndefined(userModel.customer.options.option)) {
          userModel.customer.options.option.forEach((o) => {
            if (!isNullOrUndefined(activationState.tariffData.tariffOptionGroups[o.optionGroupCode])) {
              activationState.tariffData.tariffOptionGroups[o.optionGroupCode].tariffOptions[o.optionCode].isSelected = true;
              activationState.tariffData.tariffOptionGroups[o.optionGroupCode].hasOptionSelected = true;
              activationState.tariffData.hasOptionSelected = true;
            }
          });

          // Convert MNP option to MNP data
          mnpData = this.mnpService.toMnpDataFromOptionArray(
            userModel.customer.options.option
          );
          // console.log('mnpData', mnpData);
        }

        const pc = userModel.customer.privateCustomer;

        let newDate = '';
        if (!isNullOrUndefined(pc) && !isNullOrUndefined(pc.dateOfBirth)) {

          const tmpDate = pc.dateOfBirth.includes('T') ? pc.dateOfBirth.split('T')[0] : pc.dateOfBirth; // fixing returned date

          // newDate = moment().utcOffset() >= 0 ? moment(tmpDate + 'T12:00:00.000Z').add({ minutes: 60 }).format('YYYY-MM-DD')
          //   : moment(tmpDate + 'T12:00:00.000Z').add({ minutes: 240 }).format('YYYY-MM-DD');

          newDate = this.timeHandlerService.correctDateString(tmpDate + 'T12:00:00.000Z', 'YYYY-MM-DD', null, true);
        }

        /**
         * EB-18006
         * in case returned salutation has value 'OTHER' or 'UNATTRIBUTED', we take 'UNATTRIBUTED'
         * otherwise we keep original returned value
         */
        let salutation = '';
        if (!isNullOrUndefined(pc) && !isNullOrUndefined(pc.salutation)) {
          salutation = ['OTHER', 'UNATTRIBUTED'].includes(pc.salutation) ? 'UNATTRIBUTED' : pc.salutation;
        }

        // console.log('new date:', newDate);
        activationState.customerData = {
          salutation: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.salutation) ? salutation : null,
          firstName: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.firstName) ? pc.firstName : null,
          lastName: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.lastName) ? pc.lastName : null,
          dateOfBirth: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.dateOfBirth) ? newDate : null,
          contactAddress: {
            city: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.city) ? pc.city : null,
            country: 'DEUTSCHLAND',
            street: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.street) ? pc.street : null,
            streetNumber: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.streetNumber) ? pc.streetNumber : null,
            zipCode: !isNullOrUndefined(pc) && !isNullOrUndefined(pc.zipCode) ? pc.zipCode : null,
          },
          email: !isNullOrUndefined(userModel.customer) && !isNullOrUndefined(userModel.customer.email) ? userModel.customer.email : null,
          mnpData,
          properties: !isNullOrUndefined(userModel.customer) && !isNullOrUndefined(userModel.customer.properties) ?
            userModel.customer.properties : null,
        };

        // console.log('activationState.customerData', activationState.customerData);

        const property: PropertyConsentModel = JSON.parse(JSON.stringify(initialProperties));

        activationState.noTpiCheck = !isNullOrUndefined(userModel.customer) && !isNullOrUndefined(userModel.customer.properties) &&
          // tslint:disable-next-line: max-line-length
          !isNullOrUndefined(userModel.customer.properties.property) && !isNullOrUndefined(userModel.customer.properties.property.filter(p => p.name === 'AdressIsUnknownInTPI')[0])
          // tslint:disable-next-line: max-line-length
          ? (userModel.customer.properties.property.filter(p => p.name === 'AdressIsUnknownInTPI')[0].value === 'true' ? true : false) : false;

        if (!isNullOrUndefined(userModel.customer.properties) && !isNullOrUndefined(userModel.customer.properties.property)) {
          userModel.customer.properties.property.forEach((p: IPropertyTypeDto) => {
            switch (p.name) {
              case 'bankAccountData_bankAccountOwner':
                activationState.bankAccountData.accountOwner = p.value;
                activationState.bankAccountData.selected = true;
                activationState.bankAccountData.bankAccountDataCheck = true;
                break;
              case 'bankAccountData_iban':
                activationState.bankAccountData.iban = p.value;
                activationState.bankAccountData.selected = true;
                activationState.bankAccountData.bankAccountDataCheck = true;
                break;
              case 'bankAccountData_bankName':
                activationState.bankAccountData.bankName = p.value;
                break;
              case 'bankAccountData_bic':
                activationState.bankAccountData.bic = p.value;
                break;
              case 'paymentMethod':
                activationState.bankAccountData.paymentMethod = p.value === 'USE_DEBT_WALLET' ? 'Bankkonto' : 'Guthaben';
                break;
              default:
                break;
            }
          });

          const documentKeys = ['selectedDocumentType', 'selectedDocumentCategory', 'documentValidTo', 'documentCountryIsocode'];

          userModel.customer.properties.property.forEach((p: IPropertyTypeDto) => {
            Object.keys(property).forEach(key => {
              if (key === 'document' && documentKeys.includes(p.name)) {
                const idx = property[key].findIndex((it2) => it2.name === p.name);
                if (idx !== -1) {
                  property[key][idx] = ({
                    ...(property[key][idx]),
                    value: p.value
                  });
                }
              }

              if (key === 'bestandsdaten_alle_produkte' && p.name.startsWith('bestandsdaten_alle_produkte')) {
                const idx = property[key].findIndex((it2) => it2.name === p.name);
                if (idx !== -1) {
                  property[key][idx] = ({
                    ...(property[key][idx]),
                    value: p.value
                  });
                }
              }
              if (key === 'marktforschung' && p.name.startsWith('marktforschung')) {
                const idx = property[key].findIndex((it2) => it2.name === p.name);
                if (idx !== -1) {
                  property[key][idx] = ({
                    ...(property[key][idx]),
                    value: p.value
                  });
                }
              }
              if (key === 'nutzungsprofil_bedarfsgerechte_produkte_anbieten' && p.name.startsWith('nutzungsprofil_bedarfsgerechte_produkte_anbieten')) {
                const idx = property[key].findIndex((it2) => it2.name === p.name);
                if (idx !== -1) {
                  property[key][idx] = ({
                    ...(property[key][idx]),
                    value: p.value
                  });
                }
              }
              if (key === 'nutzungsprofil_bedarfsgerechte_produkte_gestalten' && p.name.startsWith('nutzungsprofil_bedarfsgerechte_produkte_gestalten')) {
                const idx = property[key].findIndex((it2) => it2.name === p.name);
                if (idx !== -1) {
                  property[key][idx] = ({
                    ...(property[key][idx]),
                    value: p.value
                  });
                }
              }
            }
            );
          });
        }
        activationState.properties = property;
      }
    }

    if (!isNullOrUndefined(activationState.tariffData) &&
        !isNullOrUndefined(activationState.tariffData.tariffOptionGroups) &&
        !isEmptyInputValue(activationState.tariffData.tariffOptionGroups)) {
      Object.keys(activationState.tariffData.tariffOptionGroups).forEach(group => {
        Object.keys(activationState.tariffData.tariffOptionGroups[group]?.tariffOptions).forEach(option => {
          const tmpOption = activationState.tariffData.tariffOptionGroups[group].tariffOptions[option];
          // tslint:disable-next-line: max-line-length
          if (!isNullOrUndefined(tmpOption) && !isNullOrUndefined(tmpOption.properties) && !isNullOrUndefined(tmpOption.properties.property) &&
            tmpOption.properties.property.some(prop => prop.name === 'selectedByDefault') &&
            !isNullOrUndefined(tmpOption.properties.property.filter(prop => prop.name === 'selectedByDefault')[0])) {
            activationState.tariffData.tariffOptionGroups[group].tariffOptions[option].isSelected = true;
            activationState.tariffData.tariffOptionGroups[group].hasOptionSelected = true;
            if (!activationState.tariffData.hasOptionSelected) {
              activationState.tariffData.hasOptionSelected = true;
            }
          }
        });
      });
    }

    // console.log('activationState: ', activationState);
    return activationState;
  }

  public customerRegistration2(): Observable<CustomerRegistration2ResponseDto> {
    return combineLatest([this.storeActivation.select(fromActivationReducer.selectActivationState),
    this.appSettingsStore.select(fromAppSettingsSelectors.selectAppSettingsState)]).pipe(
      take(1),
      map((res) => ({ activationState: res[0], appSettingsConfig: res[1].config })),
      map((res) => this.buildCustomerRegistration2Request(res.activationState, res.appSettingsConfig)),
      // tap(res => console.log('REQUEST', res)),
      switchMap((request: CustomerRegistration2RequestDto) => this.httpClient.post<CustomerRegistration2ResponseDto>('services/V10/aldi-talk-internet-service-service/customerRegistration2', request))
    );
  }

  // tslint:disable-next-line: max-line-length
  private buildCustomerRegistration2Request(activationState: ActivationState, appSettingsConfig: AppSettingsConfigState): CustomerRegistration2RequestDto {
    const options: CustomerRegistration2RequestOptionsOptionsAnonymDto = {
      option: []
    };

    for (const groupCode of Object.keys(activationState.tariffData.tariffOptionGroups)) {
      const optionGroup = activationState.tariffData.tariffOptionGroups[groupCode];
      for (const optionCode of Object.keys(optionGroup.tariffOptions)) {
        const option = optionGroup.tariffOptions[optionCode];
        // MNP option is populated from activationState.customerData, so we ignore it here
        if (option.groupCode !== 'G0MNP' && option.isSelected) {
          options.option.push({
            optionCode,
            optionGroupCode: groupCode,
            action: 'ADD'
          });
        }
      }
    }

    const mnpOption: OptionTypeDto = this.mnpService.toMnpOptionTypeDto(activationState.customerData);
    if (mnpOption != null) {
      options.option.push(mnpOption);
    }
    const property: IPropertyTypeDto[] = [];
    if (!isNullOrUndefined(appSettingsConfig.properties) && !isEmptyInputValue(appSettingsConfig.properties)) {
      Object.keys(appSettingsConfig.properties).forEach(
        it => {
          if (appSettingsConfig.properties[it].provide === true) {
            property.push(
              { name: it.toString(), value: appSettingsConfig.properties[it].value + '' }
            );
          }
        }
      );

    }

    if (!isNullOrUndefined(activationState.properties) && !isEmptyInputValue(activationState.properties)) {
      for (const key of Object.keys(activationState.properties)) {
        if (key !== 'document') {
          for (const keyValue of activationState.properties[key]) {
            property.push(
              { name: keyValue.name, value: keyValue.value }
            );
          }
        }
      }
    }

    if (!isNullOrUndefined(activationState.customerData.mnpData) && activationState.customerData.mnpData.selected === true &&
      !isNullOrUndefined(activationState.customerData.mnpData.serviceProviderId)) {
      property.push(
        { name: 'oldMnpServiceProviderId', value: activationState.customerData.mnpData.serviceProviderId + '' }
      );
    }

    if (!isNullOrUndefined(activationState.noTpiCheck) && activationState.noTpiCheck === true) {
      property.push(
        { name: 'AdressIsUnknownInTPI', value: 'true' }
      );
    }

    if (!isNullOrUndefined(activationState.idDocumentData) &&
        !isNullOrUndefined(activationState.idDocumentData.selectedDocumentType) &&
        !isNullOrUndefined(activationState.idDocumentData.selectedDocumentCategory) &&
        !isNullOrUndefined(activationState.idDocumentData.validUntilDate) && !isNullOrUndefined(activationState.idDocumentData.country)) {
      if (!isNullOrUndefined(activationState.idDocumentData) && !isNullOrUndefined(activationState.idDocumentData.selectedDocumentType)) {
        property.push(
          { name: 'selectedDocumentType', value: activationState.idDocumentData.selectedDocumentCategory.documentType }
        );
      }

      if (!isNullOrUndefined(activationState.idDocumentData) &&
       !isNullOrUndefined(activationState.idDocumentData.selectedDocumentCategory)) {
        property.push(
          { name: 'selectedDocumentCategory', value: activationState.idDocumentData.selectedDocumentCategory.name }
        );
      }

      if (!isNullOrUndefined(activationState.idDocumentData) && !isNullOrUndefined(activationState.idDocumentData.validUntilDate)) {
        // let newDateValidUntil = '';

        // const newDateValidUntil = moment().utcOffset() >= 0 ?
        //  (moment as any)(activationState.idDocumentData.validUntilDate + 'T12:00:00Z').add({ minutes: 60 }).format('DD.MM.YYYY')
        // : (moment as any)(activationState.idDocumentData.validUntilDate + 'T12:00:00Z').add({ minutes: 240 }).format('DD.MM.YYYY');

        const newDateValidUntil = this.timeHandlerService.correctDateString(activationState.idDocumentData.validUntilDate + 'T12:00:00Z', 'DD.MM.YYYY', null, true);

        property.push(
          { name: 'documentValidTo', value: newDateValidUntil }
        );
      }

      if (!isNullOrUndefined(activationState.idDocumentData) && !isNullOrUndefined(activationState.idDocumentData.country)) {
        property.push(
          { name: 'documentCountryIsocode', value: activationState.idDocumentData.country }
        );
      }
    }

    if (isNullOrUndefined(activationState.idDocumentData.selectedDocumentType) &&
      !isNullOrUndefined(activationState.properties.document.filter(it => it.name === 'selectedDocumentCategory')[0].value) &&
      !isNullOrUndefined(activationState.properties.document.filter(it => it.name === 'selectedDocumentType')[0].value) &&
      !isNullOrUndefined(activationState.properties.document.filter(it => it.name === 'documentCountryIsocode')[0].value) &&
      !isNullOrUndefined(activationState.properties.document.filter(it => it.name === 'documentValidTo')[0].value)) {
      activationState.properties.document.forEach(it => {
        // console.log(it);
        if (it.name === 'documentValidTo') {

          const date = it.value.split('.');

          // const newDateValidTo = moment().utcOffset() >= 0 ?
          //  (moment as any)(date[2] + '-' + date[1] + '-' + date[0]).add({ minutes: 60 }).format('DD.MM.YYYY') :
          //  (moment as any)(date[2] + '-' + date[1] + '-' + date[0]).add({ minutes: 240 }).format('DD.MM.YYYY');

          const newDateValidTo = this.timeHandlerService.correctDateString(date[2] + '-' + date[1] + '-' + date[0],
           'DD.MM.YYYY', null, true);

          property.push(
            { name: it.name, value: newDateValidTo }
          );
        } else {
          property.push(
            { name: it.name, value: it.value }
          );
        }
      });
    }

    property.push(
      {
        name: 'ackMyAldiTalk', value: !isNullOrUndefined(activationState.customerData.email) &&
          !isEmptyInputValue(activationState.customerData.email) ? 'true' : 'false'
      }
    );

    property.push(
      {
        name: 'paymentMethod', value: activationState.bankAccountData.paymentMethod === 'Guthaben' ? 'USE_MAIN_BALANCE' : 'USE_DEBT_WALLET'
      }
    );

    let bankAccountData: BankAccountDataTypeDto;
    if (!isNullOrUndefined(activationState.bankAccountData) &&
      (activationState.bankAccountData.paymentMethod === 'Bankkonto' || activationState.bankAccountData.selected)) {
      bankAccountData = {
        bankIBAN: activationState.bankAccountData.iban,
        bankAccountOwner: activationState.bankAccountData.accountOwner,
        bankBIC: activationState.bankAccountData.bic,
        bankName: activationState.bankAccountData.bankName,
        alternativeAccountHolder: false,
        enableSepaMandat: 'TRUE'
      };
    }
    
    // TODO remove these lines completely?
    // In RAITT BE environment, customerRegistration2 call takes place before early ECC documentation could have possibly been downloaded
    // if (!isNullOrUndefined(this.fileData$.getValue())) {
    //   property.push({ name: 'eeccDownloadTimestamp', value: this.fileData$.getValue().dateAndTime });
    // }

    // TODO remove these lines completely?
    // In RAITT BE environment, customerRegistration2 call takes place before early ECC documentation could have possibly been downloaded
    // if (!isNullOrUndefined(this.fileData$.getValue())) {
    //   property.push({ name: 'eeccZipfilename', value: this.fileData$.getValue().fileName });
    // }

    // const newDatetmp = moment().utcOffset() >= 0 ?
    // moment(activationState.customerData.dateOfBirth).add({ minutes: 60 }).format('YYYY-MM-DD') :
    // moment(activationState.customerData.dateOfBirth).add({ minutes: 240 }).format('YYYY-MM-DD');
  
    const newDatetmp = this.timeHandlerService.correctDateString(activationState.customerData.dateOfBirth, 'YYYY-MM-DD', null, true);
    let customer: CustomerRegistration2RequestCustomerCustomerMergedDto = {
      salutation: !isNullOrUndefined(activationState.customerData.salutation) ? activationState.customerData.salutation : 'UNATTRIBUTED',
      firstName: activationState.customerData.firstName,
      lastName: activationState.customerData.lastName,
      dateOfBirth: newDatetmp,
      // dateOfBirth: format(new Date(activationState.customerData.dateOfBirth), 'yyyy-MM-dd'),
      locale: 'de',
      email: !isNullOrUndefined(activationState.customerData.email) && !isEmptyInputValue(activationState.customerData.email) ?
        activationState.customerData.email : undefined,
      // email: activationState.customerData.email
      ackTermsAndConditions: true, // setting configuration ?
      ackTrafficData: false, // setting configuration ?
      ackMarketing: false, // setting configuration?
      // ackMarketing: !isNullOrUndefined(activationState.ackMarketing) ? activationState.ackMarketing : false,
      properties: {
        property
      },
      password: activationState.customerData.password
    };
    // console.log('customerData', customer);
    if (!isNullOrUndefined(appSettingsConfig?.customerProperties)) {
      Object.keys(appSettingsConfig.customerProperties).forEach(it => {
        if (appSettingsConfig.customerProperties[it].provide === true) {
          if (it === 'ackTermsAndConditions') {
            customer = { ...customer, ackTermsAndConditions: appSettingsConfig.customerProperties[it].value };
          }
          if (it === 'ackTrafficData') {
            customer = { ...customer, ackTrafficData: appSettingsConfig.customerProperties[it].value };
          }
          if (it === 'ackMarketing') {
            customer = { ...customer, ackMarketing: appSettingsConfig.customerProperties[it].value };
          }
        }
      });
    }

    return new CustomerRegistration2RequestDto({
      processRef: {
        sim: activationState.simData.sim
      },
      tenant: activationState.tariffData.tenantId,
      customer,
      contactAddress: {
        city: activationState.customerData.contactAddress.city,
        street: activationState.customerData.contactAddress.street,
        streetNumber: activationState.customerData.contactAddress.streetNumber,
        zipCode: activationState.customerData.contactAddress.zipCode
      },
      options: options.option.length > 0 ? options : undefined,
      bankAccountData,
      tariffCode: activationState.tariffData.tariffCode,
      autoSupplyDefaults: true,
      clientVersion: !isNullOrUndefined(appSettingsConfig.clientVersion) ? appSettingsConfig.clientVersion : undefined,
      skipAddressCheck: true // skip the address validation as the address has already been validated by Telefonica BE
    });
  }

  public simReset(request: IResetProcessRequestDto): Observable<IResetProcessResponseDto> {
    const req: IResetProcessRequestDto = {
      sim: !isNullOrUndefined(request.sim) ? request.sim : undefined,
      puk2: !isNullOrUndefined(request.puk2) ? request.puk2 : undefined,
      sendCustomerEmail: !isNullOrUndefined(request.sendCustomerEmail) ? request.sendCustomerEmail : undefined,
      action: !isNullOrUndefined(request.action) ? request.action : undefined
    };

    return this.httpClient.post<IResetProcessResponseDto>('services/V10/aldi-talk-internet-service-service/resetProcess', req).pipe(
      map(res => res),
      catchError(err => {
        return of(err);
      })
    );
  }

  // EB-17713
  public identificationConfirm(request: IIdentificationConfirmRequestDto): Observable<IIdentificationConfirmResponseDto> {

    return this.httpClient.post<IResetProcessResponseDto>('services/V10/aldi-talk-internet-service-service/identificationConfirm', request)
    .pipe(
      map(res => res),
      catchError(err => {
        return of(err);
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  public processPrecontractualInformation(request: IProcessPrecontractualInformationRequestDto): Observable<IProcessPrecontractualInformationResponseDto> {
    return this.httpClient.post<IProcessPrecontractualInformationResponseDto>('services/V10/aldi-talk-internet-service-service/processPrecontractualInformation', request);
  }

  // TODO consider removing this service in RAITT environment (and its invocations)
  public downloadZip(req: string): Observable<IFileAttachmentTypeDto> {
    return this.httpClient.post<IFileAttachmentTypeDto>('services/V10/download-zip/getZIP', {filename: req});
  }

  public getPrecontractualDocument(sim: string, getContent: boolean): Observable<IGetPrecontractualDocumentResponseDto> {
    
    // TODO remove this line before committing
    // throw Error('Fabricated error in getPrecontractualDocument()');
    
    const request: IGetPrecontractualDocumentRequestDto = {
      sim: !isNullOrUndefined(sim) ? sim : undefined,
      getContent: !isNullOrUndefined(getContent) ? getContent : undefined,
    };
  
    return this.httpClient.post<IGetPrecontractualDocumentResponseDto>('services/V10/aldi-talk-internet-service-service/getPrecontractualDocument', request)
    .pipe(
      map(res => res),
      catchError(err => {
        return of(err);
      })
    );
  }

  public isOrderCancellationAllowed(sim: string, ip?: string): Observable<IsOrderCancellationAllowedResponseDto> {
    
    // TODO remove this line before committing
    // throw Error('Fabricated error in isOrderCancellationAllowed()');
    
    const request: IsOrderCancellationAllowedRequestDto = {
      sim: !isNullOrUndefined(sim) ? sim : undefined,
      ip: !isNullOrUndefined(ip) ? ip : undefined,
    };
  
    return this.httpClient.post<IsOrderCancellationAllowedResponseDto>('services/V10/aldi-talk-internet-service-service/isOrderCancellationAllowed', request)
    .pipe(
      map(res => res),
      catchError(err => {
        return of(err);
      })
    );
  }

  public continueRegistration(): Observable<ContinueRegistrationResponseDto> {
    return combineLatest([this.storeActivation.select(fromActivationReducer.selectActivationState),
    this.appSettingsStore.select(fromAppSettingsSelectors.selectAppSettingsState)]).pipe(
      take(1),
      map((res) => ({ activationState: res[0], appSettingsConfig: res[1].config })),
      map((res) => this.buildContinueRegistrationRequest(res.activationState, res.appSettingsConfig)),
      // tap(res => console.log('REQUEST', res)),

      // Mock response
      // switchMap((request: ContinueRegistrationRequestDto) => of({result: {code: 0}, paymentStatus: 3})) // 3 = red
      // Actual continueRegistration call
      switchMap((request: ContinueRegistrationRequestDto) => this.httpClient.post<ContinueRegistrationResponseDto>('services/V10/aldi-talk-internet-service-service/continueRegistration', request))
    );
  }

  // tslint:disable-next-line: max-line-length
  private buildContinueRegistrationRequest(activationState: ActivationState, appSettingsConfig: AppSettingsConfigState): ContinueRegistrationRequestDto {

    console.log(`Working on 'activationState':`, activationState);
    console.log(`Working on 'appSettingsConfig':`, appSettingsConfig);

    // Step 1 - Prepare ackTrafficData and ackMarketing

    let ackTrafficData = false;
    let ackMarketing = false;
    if (!isNullOrUndefined(appSettingsConfig?.customerProperties)) {
      Object.keys(appSettingsConfig.customerProperties).forEach(it => {
        if (appSettingsConfig.customerProperties[it].provide === true) {
          if (it === 'ackTrafficData') {
            ackTrafficData = appSettingsConfig.customerProperties[it].value;
          } else if (it === 'ackMarketing') {
            ackMarketing = appSettingsConfig.customerProperties[it].value;
          }
        }
      });
    }

    // Step 2 - Generate customer properties

    const property: IPropertyTypeDto[] = [];
    if (!isNullOrUndefined(appSettingsConfig.properties) && !isEmptyInputValue(appSettingsConfig.properties)) {
      Object.keys(appSettingsConfig.properties).forEach(
        it => {
          if (appSettingsConfig.properties[it].provide === true) {
            property.push(
              { name: it.toString(), value: appSettingsConfig.properties[it].value + '' }
            );
          }
        }
      );
    }

    if (!isNullOrUndefined(activationState.properties) && !isEmptyInputValue(activationState.properties)) {
      for (const key of Object.keys(activationState.properties)) {
        if (key !== 'document') {
          for (const keyValue of activationState.properties[key]) {
            property.push(
              { name: keyValue.name, value: keyValue.value }
            );
          }
        }
      }
    }

    // console.log(`Retrieved sim:`, activationState.simData.sim);
    // console.log(`Generated 'property':`, property);

    // Step 3 - Transform customer properties to customer consents

    const currentDatetime = moment().utc().format('YYYY-MM-DDTHH:mm:ss'); // Warning: the time has to be in UTC, but time zone designation ('ZZ' which will append '+0000') is not allowed by BE!

    let customerConsentItem: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto[] = [];
    if (property.length > 0) {
      property.forEach(p => {
        // console.log("p.value === 'true':", p.value === 'true');
        if (p.value === 'true') { // only granted consents will be stored
          let existingCustomerConsentItem = customerConsentItem.find(cci => cci.consentId === appSettingsConfig.customerConsents[p.name].consentId.toString());

          if (existingCustomerConsentItem === undefined) {
            customerConsentItem.push({
              consentId: appSettingsConfig.customerConsents[p.name].consentId,
              consentName: appSettingsConfig.customerConsents[p.name].consentName,
              consentVersion: appSettingsConfig.customerConsents[p.name].consentVersion,
              consentGranted: true,
              consentSetDateTime: currentDatetime,
              customerConsentFeatures: isNullOrUndefined(appSettingsConfig.customerConsents[p.name].feature)
                                       ? undefined
                                       : {
                                         customerConsentFeature: [
                                           {
                                             feature: appSettingsConfig.customerConsents[p.name].feature,
                                             featureType: appSettingsConfig.customerConsents[p.name].featureType
                                           }
                                         ]
                                       }
            });
          } else {
            let existingCustomerConsentFeaturesArray = existingCustomerConsentItem?.customerConsentFeatures?.customerConsentFeature;

            if (isNullOrUndefined(existingCustomerConsentFeaturesArray)) {
              existingCustomerConsentFeaturesArray = [];
            }
            existingCustomerConsentFeaturesArray.push({
              feature: appSettingsConfig.customerConsents[p.name].feature,
              featureType: appSettingsConfig.customerConsents[p.name].featureType
            });
            customerConsentItem[customerConsentItem.indexOf(existingCustomerConsentItem)] = {
              ...existingCustomerConsentItem,
              customerConsentFeatures:  {
                customerConsentFeature: existingCustomerConsentFeaturesArray
              }
            }
          }
        }
      });
    }
    
    let customerConsentSetting: CustomerConsentSettingTypeDto;
    if (customerConsentItem.length > 0) {
      customerConsentSetting = {
        id: 1,
        created: currentDatetime,
        customerConsentItems: {
          customerConsentItem
        }
      };
    }

    // TODO comment out next line
    console.log('customerConsentSetting:', customerConsentSetting);
    
    return new ContinueRegistrationRequestDto({

      // TODO uncomment following line
      sim: activationState.simData.sim,
      // sim: undefined,

      ackTrafficData,
      ackMarketing,
      customerConsentSetting
    });
  }
}
